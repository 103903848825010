<template>
  <div :data-cy="usersWrapperCy" class="users-wrapper">
    <b-table
      fixed
      id="workspaces-table"
      hover
      :items="items"
      :fields="columns"
      dark
      table-class="b-custom-table"
      :filter="searchTerm"
      no-sort-reset
      sticky-header
      sort-by="name"
      :responsive="true"
      @row-clicked="playSelectedVideo($event)"
    >
      <template v-slot:cell(name)="data">
        <span :data-cy="fileNameCy+''+data.index" v-b-tooltip.hover="data.item.name"> {{ data.item.name }}</span>
      </template>
      <template v-slot:cell(filePath)="data">
        <span :data-cy="filePathCy+''+data.index" v-b-tooltip.hover="data.item.filePath">
          {{ data.item.filePath }}</span
        >
      </template>
      <template v-slot:cell(createdDate)="data">
        <span :data-cy="createdDateCy+''+data.index" v-b-tooltip.hover="data.value"> {{ data.value }}</span>
      </template>
      <template v-slot:cell(updatedDate)="data">
        <span :data-cy="updatedDateCy+''+data.index" v-b-tooltip.hover="data.value"> {{ data.value }}</span>
      </template>
      <template v-slot:cell(status)="data">
        <div :data-cy="statusWorkspacesCy+''+data.index" class="grid-cell grid-status-cell" v-b-tooltip.hover="data.item.status">
          <b-icon-circle-fill :data-cy="notStartedStatusWorkspacesCy+''+data.index" v-if="data.item.status === 'NotStarted'"></b-icon-circle-fill>
          <b-spinner :data-cy="inProgressStatusWorkspacesCy+''+data.index" variant="primary" small v-if="data.item.status === 'InProgress'"></b-spinner>
          <b-icon-check-circle :data-cy="finishedStatusWorkspacesCy+''+data.index" v-if="data.item.status === 'Finished'" variant="success"></b-icon-check-circle>
          <b-icon-x-circle-fill :data-cy="stoppedStatusWorkspacesCy+''+data.index" v-if="data.item.status === 'Stopped'" variant="danger"></b-icon-x-circle-fill>
          <b-icon-exclamation-circle-fill :data-cy="failedStatusWorkspacesCy+''+data.index" v-if="data.item.status === 'Failed'" variant="warning"></b-icon-exclamation-circle-fill>
        </div>
      </template>
      <template v-slot:cell(editCell)="data">
        <b-icon-pencil-square
          :data-cy="editExistingWorkspacesCy+''+data.index"
          :id="'edit_click_icon_' + data.item.id"
          class="clickable"
          v-on:click="handleEditClick(data.item, $event)"
        ></b-icon-pencil-square>
        <b-tooltip
          noninteractive
          placement="left"
          custom-class="pipeline-info-tooltip"
          triggers="hover"
          :target="'edit_click_icon_' + data.item.id"
        >
          <pipeline-info-tooltip
            :showTitleDetails="false"
            :pipeline="data.item.aoiObject"
          ></pipeline-info-tooltip>
        </b-tooltip>
      </template>
      <template v-slot:cell(deleteCell)="data">
        <b-icon-trash-fill
          :data-cy="deleteExistingWorkspacesCy+''+data.index"
          v-b-tooltip.hover="'delete'"
          class="clickable"
          v-on:click="handleDeleteClick(data.item, $event)"
        ></b-icon-trash-fill>
      </template>
    </b-table>
  </div>
</template>

<script>
import * as moment from "moment";
import PipelineInfoTooltip from "@/components/shared/PipelineInfoTooltip.vue";
import Constants from "@/helpers/constants"

export default {
  name: "Workspaces",
  components: { PipelineInfoTooltip },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      searchTerm: "",
      columns: [
        { key: "status", label: "Status", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "filePath", label: "Video Path", sortable: true },
        {
          key: "createdDate",
          label: "Created On",
          sortable: true,
          formatter: (value, key, item) => {
            return moment
              .utc(value)
              .local()
              .format("MMM DD YY h:mm:ss A");
          }
        },

        {
          key: "updatedDate",
          label: "Last Updated On",
          sortable: true,
          formatter: (value, key, item) => {
            return moment
              .utc(value)
              .local()
              .format("MMM DD YY h:mm:ss A");
          }
        },
        { key: "editCell", label: "Edit" },
        { key: "deleteCell", label: "Delete" }
      ],

      usersWrapperCy: Constants.USER_WRAPPER_CY,

      statusWorkspacesCy: Constants.STATUS_WORKSPACES_CY,
      notStartedStatusWorkspacesCy: Constants.NOT_STARTED_STATUS_WORKSPACES_CY,
      inProgressStatusWorkspacesCy: Constants.IN_PROGRESS_STATUS_WORKSPACES_CY,
      finishedStatusWorkspacesCy: Constants.FINISHED_STATUS_WORKSPACES_CY,
      stoppedStatusWorkspacesCy: Constants.STOPPED_STATUS_WORKSPACES_CY,
      failedStatusWorkspacesCy: Constants.FAILED_STATUS_WORKSPACES_CY,

      fileNameCy: Constants.FILE_NAME_CY,
      filePathCy: Constants.FILE_PATH_CY,
      createdDateCy: Constants.CREATED_DATE_CY,
      updatedDateCy: Constants.UPDATED_DATE_CY,
      editExistingWorkspacesCy: Constants.EDIT_EXISTING_WORKSPACES_CY,
      deleteExistingWorkspacesCy: Constants.DELETE_EXISTING_WORKSPACES_CY

    };
  },
  validations: {},
  computed: {
    items() {
      return this.convertJsonToGridDataFormat(
        this.$store.state.pipelineConfigs.data.pipelineConfigs
      );
    }
  },
  beforeMount() {
    this.loadAoiData();
  },
  methods: {
    handleDeleteClick(entry, ev) {
      const params = {
        modal: this.$bvModal,
        callback: this.deleteCallback.bind(this, entry.id),
        title: "Confirm delete",
        text: `Are you sure you want to delete ${entry.name}?`
      };
      this.$store.dispatch("showConfirmDialog", params);
      ev.stopPropagation();
    },
    deleteCallback(id) {
      this.$store.dispatch("deletePipelineConfig", id);
    },
    handleEditClick(entry, ev) {
      this.$router.push(`/manageaoi/pipeline/${entry.id}/settings`);
      ev.stopPropagation();
    },
    loadAoiData() {
      this.$store.dispatch("getPipelineConfigs");
    },
    playSelectedVideo(video) {
      this.getVideoBehaviour(
        video.id,
        video.analyzedPath ? video.analyzedPath : video.filePath,
        video
      );
    },

    getVideoBehaviour(id, src, video) {
      this.$store.commit('SHOW_ALL_PANELS', false);
      this.$store.commit('CLEAR_VIDEO_SRC');
      this.$store.dispatch('clearSubscriptions');

      this.$store.commit("SET_SELECTED_CONTAINER", video.aoiObject.scene.containerName);
      this.$store.commit('TOGGLE_PARTIAL_VIDEO_EVENT', false);
      this.$store.commit('SET_FULL_VIDEO_DURATION', video.aoiObject.scene.metadata.duration);

      this.$store.dispatch('changeVideoDetails', {
        src: video.aoiObject.scene.analyzedFilePath ? video.aoiObject.scene.analyzedFilePath : video.aoiObject.scene.filePath,
        originalSrc: video.aoiObject.scene.filePath,
        analyzeId: video.analyzeId,
        container: video.aoiObject.scene.containerName,
        id: video.id,
        pipelineName: video.name,
        analysisSrc: video.aoiObject.scene.analyzedFilePath,
        playlistCompletedPercentage: video.status,
        thumbnailUrl: video.aoiObject.scene.thumbnailImgUrl,
        frameRate: video.aoiObject.scene.metadata.frameRate,
        pipeline: video.aoiObject
      });
      let query = this.$router.currentRoute.query;
      if (video.aoiObject.scene.analyzedFilePath) {
        this.$router.push({path: '/', query: query});
        this.$store.dispatch('reloadAllVideoAnalytics', video.analyzeId);

        if (video.analyzeId) {
          this.$store.dispatch("subscribeToWebsocketTopicOfAnalysedVideo", video.analyzeId)
        }
      } else {
        if (video.analyzeId) {
          this.$store.dispatch("subscribeToWebsocketTopicOfAnalysedVideo", video.analyzeId)
        }
        this.$router.push({path: '/', query: query});
      }
    },

    convertJsonToGridDataFormat(data) {
      return data
        .map(pipeline => {
          return {
            id: pipeline.id,
            name: pipeline.name,
            status: pipeline.scene.analysisState,
            filePath:
              pipeline.scene.containerName + "/" + pipeline.scene.filePath,
            date: moment
              .utc(pipeline.updatedDate)
              .local()
              .format("MMM DD YY h:mm:ss A"),
            createdDate: pipeline.createdDate,
            createdTimestamp: pipeline.createdDate,
            updatedDate: pipeline.updatedDate,
            updatedTimestamp: pipeline.updatedDate,
            analyzedPath: pipeline.scene.analyzedFilePath,
            analyzeId: pipeline.scene.analyzeId,
            aoiObject: pipeline
          };
        })
        .reverse();
    }
  }
};
</script>

<style lang="scss">
.users-wrapper {
  .b-table-sticky-header {
    max-height: 410px;
  }
  .b-custom-table {
    width: 100%;
    color: #fff;
    background-color: transparent;
    font-size: 12px;
    tr {
      cursor: pointer;
      color: rgb(206, 214, 224);
      &:active {
        outline: none;
      }
    }
    th {
      background-color: #212d38 !important;
      color: rgb(206, 214, 224) !important;
      border: none;
      width: 20%;
      font-size: 14px;
      font-weight: normal;
    
      &[aria-sort="ascending"],&[aria-sort="descending"]{
        color: white !important;
      }
      &:focus {
        outline: none;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(1) {
        width: 70px;
      }
      &:nth-child(6) {
        width: 35px;
      }
      &:nth-child(7) {
        width: 60px;
      }
    }
    td {
      font-size: 14px;
      font-weight: normal;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-child(1),
      &:nth-child(6),
      &:nth-child(7) {
        text-align: center;
      }
    }
  }
  .b-pagination {
    font-size: 12px;
    span {
      color: white !important;
      border-color: #4b5660 !important;
      background-color: transparent !important;
    }
    button {
      color: white !important;
      border-color: #4b5660 !important;
      background-color: transparent !important;
      &.page-link {
        color: #007bff !important;
      }
    }
    .active {
      button {
        color: white !important;
        background-color: #007bff !important;
      }
    }
  }
}
</style>
